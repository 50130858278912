<template>
  <div class="container">
    <h2>{{ this.loggedUser.username }}</h2>
    <h4>{{ dayWeek }}, {{ day }} {{ month }} {{ year }}</h4>
    <div>
      <div class="dashboard">
        <div class="card-container">
          <div class="card">
              <img src="https://www.ksnlaw.com/blog/wp-content/uploads/2018/04/49-600x325.jpg" class="card-img-top" alt="" />
              <div class="card-body">
                <h5 class="card-title">Encomedas</h5>
                <vc-donut
                  :size="200"
                  unit="px"
                  :thickness="35"
                  :total="100"
                  :has-legend="true"
                  legend-placement="left"
                  :sections="[
                    {
                      label: 'Em processamento',
                      value: percProcessing,
                    },
                    {
                      label: 'Picking Pronto',
                      value: percPicking,
                    },
                    {
                      label: 'Printing',
                      value: percPrinting,
                    },
                    {
                      label: 'Packing',
                      value: percPacking,
                    },
                  ]"
                  >TOTAL: <br />
                  <h3>{{ Number(total) }}</h3>
                </vc-donut>
                <a href="/orders" class="btn btn-primary">Ver Encomendas</a>
              </div>
            </div>
            <div class="card">
              <img src="https://pplware.sapo.pt/wp-content/uploads/2018/12/CTT-1-720x378.png" class="card-img-top" alt="" />
              <div class="card-body">
                <h5 class="card-title">Fecho de Dia CTT</h5>
                <p class="card-text">Criar guia de envio CTT</p>
                <a href="/ctt/close" class="btn btn-primary">CTTExpresso</a>
                <a href="/ctt/closeN" style="margin-right: 10px" class="btn btn-primary">CTT</a>
                <a href="/ctt/deliveryNotes" class="btn btn-primary" style="margin-right: 10px">Guia de Entrega</a>
              </div>
            </div>
            <div class="card">
              <img src="https://www.shipbob.com/wp-content/uploads/2019/01/iStock-692898468-2-optimized.webp" class="card-img-top" alt="" />
              <div class="card-body">
                <h5 class="card-title">Criar Encomenda de Pedidos de Cliente</h5>
                <p class="card-text">Podes criar uma encomenda de produtos pedidos pelos nosso clientes utilizando a nossa plataforma</p>
                <a href="/client/createOrder" class="btn btn-primary">Ver Pedidos</a>
              </div>
            </div>
            <div class="card">
              <img src="https://f.i.uol.com.br/fotografia/2020/08/13/15973512325f35a540a967d_1597351232_3x2_md.jpg" class="card-img-top" alt="" />
              <div class="card-body">
                <h5 class="card-title">Receção do produto</h5>
                <p class="card-text">Receber encomendas do produto</p>
                <a href="/receptionProducts" class="btn btn-primary">Receber</a>
              </div>
            </div>
            
            <div class="card">
              <img src="https://static05.cminds.com/wp-content/uploads/edd/MTrueEditOrderS.png" class="card-img-top" alt="" />
              <div class="card-body">
                <h5 class="card-title">Encomendas Manuais</h5>
                <p class="card-text">Formulário para criar novas encomendas</p>
                <a href="/manualOrder" class="btn btn-primary">Criar Encomenda</a>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  data: function () {
    return {
      loggedUser: [],
      total: 0,
      totalProcessing: 0,
      totalPicking: 0,
      totalPrinting: 0,
      totalPacking: 0,
      percProcessing: 0,
      percPicking: 0,
      percPrinting: 0,
      percPacking: 0,
      dayWeek: '',
      day: '',
      month: '',
      year: '',
      allMonths: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outobro', 'Novembro', 'Dezembro'],
    };
  },
  methods: {
    async getUserInformation() {
      this.loggedUser = this.getLoggedUser;
      if (this.loggedUser.length == 0) {
        let token = localStorage.getItem('token');
        let jwt = token.split('.')[1];
        this.loggedUser = JSON.parse(window.atob(jwt));
      }
      console.log(this.loggedUser.username);
    },
    async getTotalProcessing() {
      await this.$store.dispatch('getOrdersProcessing');
      this.totalProcessing = Number(this.getOrdersProcessing.length);
    },
    async getAllOrdersPicking() {
      await this.$store.dispatch('getOrdersPicking');
      this.totalPicking = Number(this.getOrdersPicking.length);
    },
    async getAllOrdersPrinting() {
      await this.$store.dispatch('getOrdersPrinting');
      this.totalPrinting = Number(this.getOrdersPrinting.length);
    },
    async getAllOrdersPacking() {
      await this.$store.dispatch('getOrdersPacking');
      this.totalPacking = Number(this.getOrdersPacking.length);
    },
    async calulatePer() {
      this.total = this.totalProcessing + this.totalPicking + this.totalPrinting + this.totalPacking;
      this.percProcessing = Number(((this.totalProcessing / this.total) * 100).toFixed(2));
      this.percPicking = Number(((this.totalPicking / this.total) * 100).toFixed(2));
      this.percPrinting = Number(((this.totalPrinting / this.total) * 100).toFixed(2));
      this.percPacking = Number(((this.totalPacking / this.total) * 100).toFixed(2));
      console.log(this.totalProcessing, this.totalPicking, this.totalPrinting, this.totalPacking, this.total);
      console.log(this.percProcessing, this.percPicking, this.percPrinting, this.percPacking, this.total);
    },
    getDay() {
      let date = new Date();
      let weekDay = date.getDay();
      this.day = date.getDate();
      let mon = date.getMonth();
      this.year = date.getFullYear();
      switch (weekDay) {
        case 1:
          this.dayWeek = 'Segunda-Feira';
          break;
        case 2:
          this.dayWeek = 'Terça-Feira';
          break;
        case 3:
          this.dayWeek = 'Quarta-Feira';
          break;
        case 4:
          this.dayWeek = 'Quinta-Feira';
          break;
        case 5:
          this.dayWeek = 'Sexta-Feira';
          break;
        case 6:
          this.dayWeek = 'Sábado';
          break;
        case 7:
          this.dayWeek = 'Domingo';
          break;
      }
      this.month = this.allMonths[mon];
    },
  },
  async created() {
    await this.getUserInformation();
    await this.getTotalProcessing();
    await this.getAllOrdersPicking();
    await this.getAllOrdersPacking();
    await this.getAllOrdersPrinting();
    await this.calulatePer();
    this.getDay();
  },
  computed: {
    ...mapGetters(['getLoggedUser']),
    ...mapGetters(['getOrdersPicking']),
    ...mapGetters(['getOrdersProcessing']),
    ...mapGetters(['getOrdersPrinting']),
    ...mapGetters(['getOrdersPacking']),
  },
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

h2 {
  margin: 50px 50px 0px 0px;
}
h4 {
  margin: 0px 50px 50px 0px;
}

.btn {
  margin-top: 10px;
  float: right;
  background: rgb(255, 147, 147);
  border: none;
  box-shadow: none !important;
}
.btn:hover,
.btn:focus {
  background: rgba(255, 147, 147, 0.658);
  border: white;
}

.card {
  margin-top: 10px;
  border-radius: 10px;
}
img {
  height: 80px;
  object-fit: cover;
  object-position: 0% 50;
}

@media (max-width: 767px) {
  body {
    padding: 10px;
  }

  .body {
    width: 100%;
    height: 100%;
  }
}

.dashboard {
  padding: 10px;
  margin-bottom: 20px;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.container{
  max-width: 90%;
}
</style>
